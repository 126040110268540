<template>
  <div class="container">
    <div class="personal__title title">Ваш отзыв</div>
    <FormReview />
  </div>
</template>

<script>
import FormReview from "./Form-review";

export default {
  components: {
    FormReview,
  },
};
</script>
